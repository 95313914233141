<template>
  <div>
    <transition name="fade">
    <div class="image-modal-container" v-if="visible">
      <img src="/static/icon/fi_close.svg" class="unselect icon-close svg-white" @click="close">
      <div class="image-wrapper">
        <div class="grid-image">
          <svg @click="clickLeft" class="unselect"
            width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6"
                  :stroke="leftColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div>
            <img :src="imgs[selectedIndex]" class="img-selected">
            <div class="grid-preview">
              <img v-for="(preview, idx) in imgs" :key="`preview-${idx}`" @click="clickImg(idx)"
                  :src="preview" alt="" class="unselect img-preview"
                  :class="{'preview-selected': selectedIndex === idx}">
            </div>
          </div>

          <svg @click="clickRight" class="unselect"
            width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18L15 12L9 6"
                  :stroke="rightColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </div>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "ImageModal",
    props: {
      imgs: {
        type: Array
      }
    },
    data() {
      return {
        visible: false,
        selectedIndex: 0
      }
    },
    computed: {
      leftColor() {
        return this.selectedIndex === 0 ? 'rgba(255, 255, 255, 0.3)' : '#ffffff';
      },
      rightColor() {
        return this.selectedIndex === this.imgs.length-1 ? 'rgba(255, 255, 255, 0.3)' : '#ffffff';
      }
    },
    methods: {
      clickLeft() {
        if(this.selectedIndex > 0) {
          this.selectedIndex--;
        }
      },
      clickRight() {
        if(this.selectedIndex < this.imgs.length-1) {
          this.selectedIndex++;
        }
      },
      clickImg(index) {
        this.selectedIndex = index;
      },
      open() {
        setTimeout(()=>{
          this.selectedIndex = 0;
          this.visible = true;
        }, 100);
      },
      close() {
        this.visible = false;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .image-modal-container
    width 100%
    height 100%
    position fixed
    top 0
    left 0
    z-index 1000
    background-color rgba(0, 0, 0, 0.5)
    .image-wrapper
      position absolute
      top 20vh
      left 50%
      transform translateX(-50%)
    .icon-close
      width 30px
      height 30px
      position absolute
      top 36px
      right 36px

    .img-selected
      width 424px
      height 424px
      object-fit cover

    .grid-image
      display grid
      grid-template-columns 40px 1fr 40px
      grid-column-gap 48px
      align-items center
    .grid-preview
      margin-top 20px
      display grid
      grid-template-columns repeat(5, 1fr)
      grid-gap 8px
      .img-preview
        width 100%
        height 80px
        object-fit cover
      .preview-selected
        border 2px solid #ff5722

</style>
